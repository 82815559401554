<script setup lang="ts">
import { type Ref, ref, type ComputedRef } from 'vue';
import { useCmsHead } from '@/composables/useCmsHead';
import { useBreadcrumbsMainNavigation } from '@/composables/useBreadcrumbsMainNavigation';
import { Listing } from '~/helpers/Listing';
import { useCmsStore } from '~/composables/useCmsStore';
import type { Schemas, RequestReturnType } from '#shopware';
import type { Breadcrumb } from '@shopware-pwa/composables-next';

const props = defineProps<{
    page: Ref<RequestReturnType<'pwaResolvePage'>>;
}>();

const { page } = toRefs(props);

const { addCategoryCacheTag, addProductCacheTag } = useCache();
const pageCategory = ref(props.page.value.category);
const { setLastCategory, clearLastCategoryStore } = useCmsStore();

const { category } = useCategory(pageCategory as Ref<Schemas['Category']>);
useCmsHead(category);

const mainNavigation = inject('swNavigation-main-navigation') as ComputedRef<Schemas['Category'][]>;

const breadcrumbs = page.value.breadcrumbs;
useBreadcrumbsMainNavigation(mainNavigation, breadcrumbs as Breadcrumb[]);

// save last visited category details to store
if (breadcrumbs && breadcrumbs.length > 0 && page.value.category) {
    const localLastCategory = {
        id: page.value.category.id,
        breadcrumb: breadcrumbs as Breadcrumb[],
        seoPath: page.value.category.seoUrls?.[0].seoPathInfo,
        path: page.value.category.path || '',
    };
    setLastCategory(localLastCategory);
} else {
    // clear on homepage
    clearLastCategoryStore();
}

addCategoryCacheTag(category.value.id);

const listingProducts = Listing.getListingProducts(category.value?.cmsPage?.sections);
if (listingProducts) {
    listingProducts.forEach((product: Schemas['Product']) => {
        addProductCacheTag(product.id);
    });
}

const { isHomePath } = useSeoPath();
const { addLastSeen, resetCurrentItem } = useLastSeen('category');
if (!isHomePath.value && pageCategory.value?.id) {
    addLastSeen(pageCategory.value.id);
}

const listingHits = ref<number | undefined>(undefined);
provide('listingHits', listingHits);

onBeforeRouteUpdate(() => {
    resetCurrentItem();
});
onBeforeRouteLeave(() => {
    resetCurrentItem();
});
</script>

<template>
    <CmsPage
        v-if="page.cmsPage"
        :content="page.cmsPage as Schemas['CmsPage']"
    />
</template>
